export async function getGua() {
	const gua = await import(/* webpackChunkName: "gua-async" */ '../components/gua');
	return;
}

export async function getCookieNotice() {
	const cookieNotice = await import(/* webpackChunkName: "cookie-notice-async" */ '../base/cookie-notice');
	return;
}

export default { getGua, getCookieNotice };